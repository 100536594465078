import React from 'react';
import {graphql} from 'gatsby';
import Helmet from 'react-helmet';
import get from 'lodash/get';
import Img from 'gatsby-image';
import Link from 'gatsby-link';
//import PageTransition from 'gatsby-plugin-page-transitions';

//import heroStyles from '../components/hero.module.css';
import SEO from '../components/SEO';
import icon32 from '../assets/images/favicon-32x32.png';
import Template from '../components/layout';

class BlogPostTemplate extends React.Component {
  render () {
    const post = get (this.props, 'data.contentfulBlogPost');

    return (
      <Template location={get (this, 'props.location')}>
        <div
          className="wrapper blogpost"
          style={{
            background: 'none 0px 0px repeat scroll rgba(255, 255, 255, 0.7)',
          }}
        >
          <Helmet
            title={`${post.title} | Ilse Valle-Jones`}
            link={[
              {rel: 'shortcut icon', type: 'image/png', href: `${icon32}`},
              {
                rel: 'preload',
                href: '/fonts/patua-one/patua-one-v7-latin-regular.subset.woff2',
                as: 'font',
                type: 'font/woff2',
                crossorigin: '',
              }
            ]}
          >
            <html lang="en" />
          </Helmet>
          <SEO
            seodesc={`${post.seodesc}`}
            socialImage={post.heroImage.resolutions.src}
            socialTitle={post.title}
            twitter={'@ilsevallejones'}
          />
          <div>
            <Img
              style={{maxHeight: '400px', height: '61.8vh'}}
              alt={post.heroImage.description}
              title={post.heroImage.description}
              sizes={post.heroImage.sizes}
              loading="eager"
              fadeIn={100}
            />
          </div>
          <div className="container" style={{paddingTop: '1.5em'}}>
            <div className="row">
              <div className="span7 offset1">
                <h1 className="blogh1">{post.title}</h1>
                <div
                  dangerouslySetInnerHTML={{
                    __html: post.body.childMarkdownRemark.html,
                  }}
                />
              </div>
              <div className="span4">
                <div className="widget-area">
                  <aside id="text-4" className="widget widget_text">
                    <h3 className="widget-title">Ilse Valle-Jones, MS</h3>{' '}
                    <div className="textwidget">
                      <p>Nutritionist Psychotherapist</p>
                      <Link to="/#contact" style={{color: '#bc360a'}}>
                        Contact Me
                      </Link>
                    </div>
                  </aside>
                  <aside id="text-3" className="widget widget_text">
                    {' '}
                    <div className="textwidget">
                      I am a bilingual professional nutritionist
                      psychotherapist. I can be your guide in developing a
                      healthy eating plan and give you personalized advice to
                      lose weight, exercise, and enjoy a healthy life style.
                    </div>
                  </aside>
                </div>
              </div>
            </div>
          </div>
          <hr style={{margin: '0'}} />
        </div>
      </Template>
    );
  }
}

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      seodesc
      heroImage {
        description
        sizes(maxHeight: 800, quality: 90) {
          ...GatsbyContentfulSizes_withWebp
        }
        resolutions(height:800) {
          src
        }
      }
      publishDate(formatString: "MMMM Do, YYYY")
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
